<template>
  <div>
    <b-card v-if="blogData != null" class="blog-edit-wrapper">
      <b-row>
        <b-col cols="9">
          <!-- media -->
          <b-media no-body vertical-align="center">
            <b-media-aside class="mr-75">
              <b-avatar size="38" :src="blogData.authorImage" />
            </b-media-aside>
            <b-media-body>
              <h6 class="mb-25">
                {{ blogData.author }}
              </h6>
              <b-card-text>{{
                blogData.createdAt | formattedDate
              }}</b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
        <b-col cols="3">
          <span>
            Currently updating
            <b-badge pill variant="danger" class="mr-1 ml-auto">
              {{ currentLanguage }}
            </b-badge>
            content
          </span>
        </b-col>
      </b-row>
      <!--/ media -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- form -->
        <b-form
          class="mt-2"
          @submit.prevent="handleSubmit(sendInformation)"
          @reset.prevent="resetForm"
        >
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="$t('Post Title')"
                rules="required"
              >
                <b-form-group
                  :label="$t('Post Title')"
                  label-for="blog-edit-title"
                  class="mb-2"
                >
                  <b-form-input
                    id="blog-edit-title"
                    :state="getValidationState(validationContext)"
                    v-model="blogData.title"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Category"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <category-picker
                  :initialCategories="blogData.BlogCategories"
                  @selectionChanged="blogCategoryUpdated"
                ></category-picker>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group
                label="Expires"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <b-form-checkbox v-model="blogData.expires" value="true">
                  Expires
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <validation-provider
                #default="validationContext"
                name="Post Expire Date"
                :rules="Boolean(blogData.expires) ? 'required' : null"
              >
                <b-form-group
                  label="Expire Date"
                  label-for="expireDate"
                  class="mb-2"
                  v-if="Boolean(blogData.expires)"
                  :state="getValidationStateDate(validationContext)"
                >
                  <flat-pickr
                    v-model="blogData.expireDate"
                    class="form-control"
                    input-id="expireDate"
                    :config="disabledDates"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationStateDate(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Status"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <v-select
                  id="blog-edit-category"
                  v-model="blogStatus"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOption"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Publisher"
                label-for="blog-edit-category"
                class="mb-2"
              >
                <publisher-picker
                  :initialCategories="blogData.publisher"
                  @selectionChanged="blogPublisherUpdated"
                ></publisher-picker>
              </b-form-group>
            </b-col>
            <b-col cols="6" class="mb-2">
              <div class="border rounded p-2">
                <h4 class="mb-1">
                  Featured Image
                </h4>
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        rounded
                        :src="blogData.featuredImage"
                        height="100"
                        alt=""
                        width="100"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      :state="Boolean(file)"
                      v-model="file"
                      @click="$refs.refInputEl.$el.click()"
                    >
                      Upload
                    </b-button>
                    <b-form-file
                      ref="refInputEl"
                      v-model="profileFile"
                      accept=".jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @input="fileSelected"
                    />
                    <!--/ upload button -->

                    <!-- reset -->

                    <!--/ reset -->
                    <b-card-text>{{
                      $t("Allowed JPG, GIF or PNG")
                    }}</b-card-text>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Content"
                label-for="blog-content"
                class="mb-2"
              >
                <vue-editor
                  id="editor"
                  :editorOptions="editorSettings"
                  @imageAdded="handleImageAdded"
                  v-model="blogData.content"
                />
              </b-form-group>
            </b-col>
            <b-col cols="10" class="mt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                type="submit"
              >
                Save Changes
              </b-button>
            </b-col>
            <b-col cols="2" class="mt-50">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="mr-1"
                @click.prevent="deleteContent"
              >
                Delete Post
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
      <!--/ form -->
    </b-card>
  </div>
</template>

<script>
import {
  BBadge,
  BCard,
  BFormCheckbox,
  BFormInvalidFeedback,
  BMedia,
  BAvatar,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { VueEditor, Quill } from "vue2-editor";
import router from "@/router";
import store from "@/store";
import CategoryPicker from "../components/BlogCategoryPicker.vue";
import PublisherPicker from "../../blogpublishers/components/DepartmentPicker.vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import courseStoreModule from "../departmentStoreModule";
import { get } from "echarts/lib/CoordinateSystem";
import formValidation from "@core/comp-functions/forms/form-validation";
import flatPickr from "vue-flatpickr-component";
import { required, alphaNum, email } from "@validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    PublisherPicker,
    BFormInvalidFeedback,
    flatPickr,
    BBadge,
    BCard,
    BMedia,
    BAvatar,
    BFormCheckbox,
    BCardText,
    BMediaAside,
    BMediaBody,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    CategoryPicker,
    quillEditor,
    VueEditor,
  },
  directives: {
    Ripple,
  },
  filters: {
    formattedDate: function (value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY HH:mm");
      }
    },
  },
  computed: {
    expireDate: {
      get() {
        if (
          this.blogData.expireDate == null ||
          this.blogData.expireDate == undefined
        ) {
          return null;
        }
        return moment(this.blogData.expireDate).format("Y-m-d H:i");
      },
      set(value) {
        this.blogData.expireDate = value;
      },
    },

    disabledDates() {
      const today = new Date();
      const tomorrow = new Date();
      //console.log("======================");

      // Add 1 Day
      tomorrow.setDate(today.getDate() + 1);
      //console.log(tomorrow)
      var option = {
        enableTime: true,
        dateFormat: "Y-m-d H:i",
        disable: [{ from: "1970-08-20", to: tomorrow }],
      };
      return option;
    },

    blogStatus: {
      get() {
        var result = this.blogData.status == 0 ? "Draft" : "Published";
        return result;
      },
      set(newValue) {
        if (newValue == "Draft") {
          this.blogData.status = 0;
        } else {
          this.blogData.status = 100;
        }
      },
    },
  },

  methods: {
    setEditorContent() {
      this.content = this.blogData.excerpt;
    },
    resetFile() {
      this.file = null;
      this.profileFile = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },
    blogPublisherUpdated(payload) {
      //console.log("Blog publisher updated");
      //console.log(payload);
      if (payload != null || payload != undefined) {
        var publisher = JSON.parse(JSON.stringify(payload));
        this.blogData.publisherId = publisher.id;
        this.blogData.publisherName = publisher.title;
        this.blogData.publisherTitle = publisher.headline;
        this.blogData.publisherImage = publisher.image;
      } else {
        this.blogData.publisherId = null;
        this.blogData.publisherName = null;
        this.blogData.publisherTitle = null;
        this.blogData.publisherImage = null;
      }
    },

    blogCategoryUpdated(payload) {
      //console.log("Blog categories updated");
      //console.log(payload);
      this.blogData.BlogCategories = Array.isArray(payload)
        ? payload
        : [payload];
    },
    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("postid", 2);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          this.blogData.featuredImage = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          this.$bvToast.toast(`${response.data.message}`, {
            title: "Success",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
            solid: true,
          });
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },
    deleteContent() {
      //  //console.log(this.optionsLocal);
      //  //console.log("update course  is launching with id : " + this.courseId);

      //console.log("sending blog data");
      //console.log(this.blogData);
      (this.blogData.deleted = true), (this.isLoading = true);
      axios({
        method: "put",
        url: `/cms/admin/updateblogpost`,
        data: {
          info: "general",
          file: null,
          content: "",
          post: this.blogData,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
          router.push({ name: "cms-blogs" });
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
              text: r,
            },
          });
          this.isLoading = false;
        });
      return;
    },
    sendInformation() {
      //  //console.log(this.optionsLocal);
      //  //console.log("update course  is launching with id : " + this.courseId);

      //console.log("sending blog data");
      //console.log(this.blogData);

      if (
        this.blogData.expires != null &&
        this.blogData.expires != undefined &&
        this.blogData.expires != "" &&
        this.blogData.expires === true
      ) {
        //console.log(this.blogData.expireDate);
        if (
          this.blogData.expireDate == null ||
          this.blogData.expireDate == undefined ||
          this.blogData.expireDate == ""
        ) {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "dander",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
          return;
        }
      }

      this.isLoading = true;
      axios({
        method: "put",
        url: `/cms/admin/updateblogpost`,
        data: {
          info: "general",
          file: null,
          content: "",
          post: this.blogData,
        },
      })
        .then((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Success`,
              icon: "CheckCircleIcon",
              variant: "success",
            },
          });
          this.isLoading = false;
          this.isUpdated = false;
        })
        .catch((r) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error`,
              icon: "AlertOctagonIcon",
              variant: "warning",
            },
          });
          this.isLoading = false;
        });
      return;
    },
    moment: function () {
      return moment();
    },
    handleImageAdded: function (file, Editor, cursorLocation, resetUploader) {
      // An example of using FormData
      // NOTE: Your key could be different such as:
      // formData.append('file', file)

      var formData = new FormData();
      formData.append("image", file);
      Editor.insertEmbed(
        cursorLocation,
        "image",
        "https://cdn.niso.dev/8c1fb068-5aaf-4f37-9a88-a4debe1509ca/734a3c12-728c-41b2-96b6-c181e129effb.png"
      );
      resetUploader();
      // axios({
      //   url: "https://fakeapi.yoursite.com/images",
      //   method: "POST",
      //   data: formData
      // })
      //   .then(result => {
      //     const url = result.data.url; // Get url from response
      //     Editor.insertEmbed(cursorLocation, "image", url);
      //     resetUploader();
      //   })
      //   .catch(err => {
      //     //console.log(err);
      //   });
    },
  },
  data() {
    var toolbarOptions = [
      ["bold", "italic", "underline", "strike"], // toggled buttons
      ["blockquote", "code-block"],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: "ordered" }, { list: "bullet" }],
      [{ script: "sub" }, { script: "super" }], // superscript/subscript
      // outdent/indent
      [{ direction: "rtl" }], // text direction

      [{ size: ["small", false, "large", "huge"] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ["clean"], // remove formatting button
      ["link", "image", "video"],
      ["html"],
    ];

    return {
      editorSettings: {
        modules: {},
      },
      profileFile: null,
      isLoading: false,
      file: null,

      blogFile: null,
      categoryOption: [
        "HR Commmunication",
        "Memorandum",
        "Announcement",
        "Info",
        "Weekly News",
      ],
      statusOption: ["Draft", "Published"],
      snowOption: {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
      },
    };
  },
  created() {},
  mounted() {},
  setup() {
    //console.log("setup is launching");
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      (base64) => {
        refPreviewEl.value.src = base64;
      }
    );
    const currentLanguage = ref(null);
    currentLanguage.value = localStorage.getItem("CURRENT_LANGUAGE") ?? "EN";
    onMounted(() => {
      currentLanguage.value = localStorage.getItem("CURRENT_LANGUAGE") ?? "EN";
    });

    const blogData = ref(null);

    const USER_APP_STORE_MODULE_NAME = "app-blog-edit";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, courseStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    store
      .dispatch("app-blog-edit/fetchPost", { id: router.currentRoute.query.p })
      .then((response) => {
        //console.log("Blog Fethed");
        //    var course=JSON.parse(JSON.stringify(response.data));
        //  const { course  } = response.data
        blogData.value = response.data.data;
        // blogData.publisher=response.data.data.publisher==null||response.data.data.publisher==undefined?null: JSON.parse(JSON.stringify(response.data.data.publisher));
        //console.log(response.data);
        //console.log("  assigned to blog data");
        // //console.log(response.data);
        var translations = response.data.data.translations;
        var currentLanguage = localStorage.getItem("CURRENT_LANGUAGE") ?? "EN";
        var currentTranslation = translations.find(
          (x) => x.language == currentLanguage
        );
        //console.log(currentTranslation);

        blogData.value.title = currentTranslation.title;
        blogData.value.description = currentTranslation.description;
        blogData.value.content = currentTranslation.content;
        blogData.value.language = currentTranslation.language;
      })
      .catch((error) => {
        //console.log(error);
        // if (error.response.status === 404) {
        //   blogData.value = undefined
        // }
      });
    const resetuserData = () => {};
    const onSubmit = () => {
      //console.log("console on submit");
    };

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetuserData
    );

    const getValidationStateDate = ({
      dirty,
      validated,
      required: fieldRequired,
      changed,
      valid = null,
    }) => {
      const result = dirty || validated ? valid : null;

      //console.log("validation runned: and ruselut ",result);
      if (result == null) {
        return false;
      }

      return !fieldRequired && !changed ? null : result;
    };

    return {
      getValidationStateDate,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      blogData,
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      currentLanguage,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";

.ql-showHtml:after {
  content: "[source]";
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
